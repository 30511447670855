import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function CreateChatbot2EndpointGetHook(
    param_title: string, 
    param_description: string, 
    param_instructions: string, 
) {
    return GetWithHook(routes.CreateChatbot, {
        title: param_title, 
        description: param_description, 
        instructions: param_instructions, 
    });
}

export function CreateChatbot2EndpointGetPromise(
    param_title: string, 
    param_description: string, 
    param_instructions: string, 
) {
    return GetWithPromise(routes.CreateChatbot, {
        title: param_title, 
        description: param_description, 
        instructions: param_instructions, 
    });
}

export function CreateChatbot2EndpointPostPromise(
    param_title: string, 
    param_description: string, 
    param_instructions: string, 
) {
    return PostWithPromise(routes.CreateChatbotPost, {
        title: param_title, 
        description: param_description, 
        instructions: param_instructions, 
    });
}

