import { theme } from './theme';

function HorizontalRule() {
    return (
        <hr
            style={{
                width: '100%',
                height: 1,
                border: 'none',
                backgroundColor: theme.palette.divider,
                margin: '8px 0',
            }}
        />
    );
}

export default HorizontalRule;
