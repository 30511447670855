import { Box, Button, Checkbox, CircularProgress, Container, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Chatbot from './generals/Chatbot';
import useEndpoint from './generals/useEndpoint';
import useTranslationState from './generals/useTranslationState';
import { useBlack } from './hooks/useBlack';
import { MuiColorInput } from 'mui-color-input';
import GetChatbotResponse from './responses/GetChatbot';
import { EditChatbot2EndpointPostPromise } from './routes/EditChatbot';
import { GetChatbot2EndpointGetHook, GetChatbot2EndpointGetPromise } from './routes/GetChatbot';
import { Import2EndpointGetPromise, Import2EndpointPostPromise } from './routes/Import';
import Notification from './generals/notification';
import useIsMobile from './hooks/useIsMobile';
import { ImportLinkModel } from './models/ImportLinkModel';
import ImportedLinksResponse from './responses/ImportedLinks';
import { ImportedLinks2EndpointGetHook } from './routes/ImportedLinks';
import { ImportingModel } from './models/ImportingModel';
import ImportResponse from './responses/Import';
import useFrequentEndpoint from './generals/useFrequentEndpoint';

interface Link {
    href: string;
    text: string;
}

function Configuration() {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const [update, setUpdate] = useState<boolean>(false);

    const translationState = useTranslationState({
        title: '',
        insertScript: '',
        description: '',
        instructions: '',
        importFromLinks: '',
        installInstructions: '',
        savingEnabled: '',
        savingDisabled: '',
        enabledText: '',
        disabledText: '',
        save: '',
        model: '',
        initialMessage: '',
        allDataHasBeenImported: '',
        imported: '',
        website: '',
        import: '',
        primaryColor: '',
        secondaryColor: '',
        saveMessages: '',
        saved: '',
        noChanges: '',
        error: '',
        defaultModel: '',
        highQualityModel: '',
        enabled: '',
        highQualityModelBigContextSize: '',
        conversations: '',
        config: '',
        wordPressExplanation: '',
        running: '',
        finished: '',
        failed: '',
    });

    useBlack();

    const [chatBotState, setChatBotState] = useEndpoint<GetChatbotResponse>(GetChatbot2EndpointGetHook(id!));
    const [chatBotImportState, setChatBotImportState] = useFrequentEndpoint<GetChatbotResponse, any>(GetChatbot2EndpointGetHook(id!), GetChatbot2EndpointGetPromise, 5, id!);

    const [titleState, setTitleState] = useState<string>('');
    const [descriptionState, setDescriptionState] = useState<string>('');
    const [initialMessageState, setInitialMessageState] = useState<string>('');
    const [instructionsState, setInstructionsState] = useState<string>('');
    const [modelState, setModelState] = useState<string>('');
    const [importedState, setImportedState] = useState<boolean>(false);
    const [saveMessagesState, setSaveMessagesState] = useState<boolean>(false);
    const [enabledState, setEnabledState] = useState<boolean>(true);

    const [primaryColorState, setPrimaryColorState] = useState<string>('');
    const [secondaryColorState, setSecondaryColorState] = useState<string>('');

    const [websiteState, setWebsiteState] = useState<string>('');

    const [notificationMessageState, setNotificationMessageState] = useState<string>('');
    const [notificationSeverityState, setNotificationSeverityState] = useState<string>('info' as 'info' | 'success' | 'warning' | 'error');
    const [notificationOpenState, setNotificationOpenState] = useState<boolean>(false);

    useEffect(() => {
        if (chatBotState.status === 200) {
            setTitleState(chatBotState.data.title);
            setDescriptionState(chatBotState.data.description);
            setInitialMessageState(chatBotState.data.initialMessage);
            setModelState(chatBotState.data.model);
            setInstructionsState(chatBotState.data.instructions);
            setSaveMessagesState(chatBotState.data.saveMessages);
            setEnabledState(chatBotState.data.enabled);
            setWebsiteState(chatBotState.data.lastImportedUrl);

            setPrimaryColorState(chatBotState.data.primaryColor);
            setSecondaryColorState(chatBotState.data.secondaryColor);
            setChatBotImportState(chatBotState);
        }
    }, [chatBotState]);

    const save = () => {
        const promise = EditChatbot2EndpointPostPromise(
            id!,
            titleState,
            descriptionState,
            initialMessageState,
            modelState,
            instructionsState,
            saveMessagesState.toString(),
            enabledState.toString(),
            primaryColorState,
            secondaryColorState,
        );

        promise.then((response) => {
            if (response.status === 200) {
                setNotificationMessageState(translationState.saved);
                setNotificationSeverityState('success');
                setNotificationOpenState(true);
                setUpdate(!update);
            } else if (response.status === 304) {
                setNotificationMessageState(translationState.noChanges);
                setNotificationSeverityState('info');
                setNotificationOpenState(true);
            } else {
                setNotificationMessageState(translationState.error);
                setNotificationSeverityState('error');
                setNotificationOpenState(true);
            }

            const promise = GetChatbot2EndpointGetPromise(id!);

            promise.then((response) => {
                setChatBotState(response);
            });
        });
    };

    const [linksState, setLinksState] = useState<Link[]>([]);
    const [importedLinksState, setImportedLinksState] = useState<ImportLinkModel[]>([]);
    const [importingState, setImportingState] = useState<ImportingModel | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedLinksState, setSelectedLinksState] = useState<string[]>([]);

    const [previouslyImportedLinksState, setPreviouslyImportedLinksState] = useEndpoint<ImportedLinksResponse>(ImportedLinks2EndpointGetHook(id!));

    useEffect(() => {
        const selectedLinks: string[] = [];

        importedLinksState.forEach((importedLink) => {
            selectedLinks.push(importedLink.link);
        });

        setSelectedLinksState(removeDuplicates(selectedLinks));
    }, [importedLinksState]);

    const importWebsite = () => {
        setLoading(true);
        setLinksState([]);
        setImportedLinksState([]);
        setImportedState(false);

        const promise = Import2EndpointGetPromise(id!, websiteState, '');

        promise.then((response) => {
            if (response.status === 200) {
                setLinksState(response.data.links);
                setImportedLinksState(response.data.imported);
                setImportingState(response.data.importing);
            }
            setLoading(false);
        });
    };

    const isImported = (link: string) => {
        importedLinksState.forEach((importedLink) => {
            if (importedLink.link === link) {
                return true;
            }
        });

        return false;
    };

    const importFromLinks = () => {
        setLoading(true);

        const jsonLinks = JSON.stringify(selectedLinksState);

        const promise = Import2EndpointPostPromise(id!, websiteState, jsonLinks);

        promise.then((response) => {
            response.json().then((response: ImportResponse) => {
                setLoading(false);
                setImportingState(response.data.importing);

                const promise2 = GetChatbot2EndpointGetPromise(id!);

                promise2.then(() => {
                    setImportedState(true);
                }).catch(() => {
                });
            });
        });
    };

    const selectLink = (link: Link) => {
        setSelectedLinksState(removeDuplicates([...selectedLinksState, link.href]));
    };

    const unselectLink = (link: Link) => {
        setSelectedLinksState(removeDuplicates(selectedLinksState.filter((selectedLink) => selectedLink !== link.href)));
    };

    const removeDuplicates = (array: string[]) => array.filter((value, index, self) => self.indexOf(value) === index);

    const isSelected = (link: Link) => selectedLinksState.includes(link.href);

    function LinkRow(link: Link) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <FormControl fullWidth>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isSelected(link)}
                                onChange={() => {
                                    if (isSelected(link)) {
                                        unselectLink(link);
                                    } else {
                                        selectLink(link);
                                    }
                                }}
                            />
                        }
                        label={
                            <Typography>
                                {link.href}
                            </Typography>
                        }
                    />
                </FormControl>
            </Box>
        );
    }

    const scriptTag = `<script src='https://taiger.tools/chatbot.js' data-chatbot-id='${id}'></script>`;

    return (
        <Container
            maxWidth="xl"
            sx={{
                paddingY: 5,
                minHeight: { xs: 1000, md: 'calc(100vh - 133px)' },
            }}
        >
            {!isMobile &&
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mb: 2,
                    }}
                >
                    <ToggleButtonGroup
                        sx={{
                            backgroundColor: 'white',
                        }}
                    >
                        <ToggleButton
                            value="left"
                            selected={true}
                            onClick={() => {
                                navigate('/config/' + id, { replace: true });
                            }}
                        >
                            {translationState.config}
                        </ToggleButton>
                        <ToggleButton
                            value="right"
                            selected={false}
                            onClick={() => {
                                navigate('/conversations/' + id, { replace: true });
                            }}
                            disabled={chatBotState.status === 200 && chatBotState.data.saveMessages !== true}
                        >
                            {translationState.conversations}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            }
            {chatBotState.status === 200 &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        backgroundColor: 'white',
                        borderRadius: 4,
                        p: 4,
                    }}
                >
                    <FormControl>
                        <FormControlLabel
                            label={translationState.enabled}
                            control={
                                <Checkbox
                                    checked={enabledState}
                                    onChange={(e) => setEnabledState(e.target.checked)}
                                    id='save-messages'
                                />
                            }
                        />
                        <Typography
                            variant="subtitle2"
                            color="textSecondary"
                        >
                            {enabledState ? translationState.enabledText : translationState.disabledText}
                        </Typography>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            value={titleState}
                            onChange={(e) => setTitleState(e.target.value)}
                            label={translationState.title}
                            variant="outlined"
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            value={descriptionState}
                            onChange={(e) => setDescriptionState(e.target.value)}
                            label={translationState.description}
                            variant="outlined"
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            value={initialMessageState}
                            onChange={(e) => setInitialMessageState(e.target.value)}
                            label={translationState.initialMessage}
                            variant="outlined"
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel
                            htmlFor='model-select'
                            color="primary"
                        >
                            {translationState.model}
                        </InputLabel>
                        <Select
                            value={modelState}
                            onChange={(e) => setModelState(e.target.value as string)}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            id='model-select'
                            label={translationState.model}
                        >
                            <MenuItem
                                value="gpt-4o-mini"
                            >
                                {translationState.highQualityModel}
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormControlLabel
                            label={translationState.saveMessages}
                            control={
                                <Checkbox
                                    checked={saveMessagesState}
                                    onChange={(e) => setSaveMessagesState(e.target.checked)}
                                    id='save-messages'
                                />
                            }
                        />
                        <Typography
                            variant="subtitle2"
                            color="textSecondary"
                        >
                            {saveMessagesState ? translationState.savingEnabled : translationState.savingDisabled}
                        </Typography>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            value={instructionsState}
                            onChange={(e) => setInstructionsState(e.target.value)}
                            label={translationState.instructions}
                            variant="outlined"
                            multiline
                            rows={8}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <MuiColorInput
                            format="hex"
                            label={translationState.primaryColor}
                            onChange={(color) => setPrimaryColorState(color)}
                            value={primaryColorState}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <MuiColorInput
                            format="hex"
                            label={translationState.secondaryColor}
                            onChange={setSecondaryColorState}
                            value={secondaryColorState}
                        />
                    </FormControl>
                    <FormControl>
                        <Button
                            onClick={save}
                            variant="contained"
                            sx={{
                                color: 'white',
                            }}
                        >
                            {translationState.save}
                        </Button>
                    </FormControl>
                </Box>
            }
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    backgroundColor: 'white',
                    borderRadius: 4,
                    p: 4,
                    marginTop: 2,
                }}
            >
                <Typography variant="h6">
                    {translationState.import}
                </Typography>
                {chatBotImportState.status === 200 && chatBotImportState.data.importing !== null && !loading &&
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            mb: 4,
                        }}
                    >
                        <Typography>
                            {translationState.imported}: {chatBotImportState.data.importing.imported}/{chatBotImportState.data.importing.total}
                        </Typography>
                        <Typography>
                            {translationState[chatBotImportState.data.importing.status as keyof typeof translationState]}
                        </Typography>
                    </Box>
                }
                {loading
                    ? <CircularProgress
                        color="primary"
                        size={100}
                    />
                    : <Box>
                        {!importedState &&
                            <Box
                                sx={{
                                    maxHeight: 600,
                                    overflowY: 'auto',
                                }}
                            >
                                {linksState.length > 0 && linksState.map((link, index) => (
                                    <LinkRow
                                        key={index}
                                        href={link.href}
                                        text={link.text}
                                    />
                                ))}
                            </Box>
                        }
                        {!importedState && linksState.length > 0 &&
                            <Button
                                variant="contained"
                                onClick={importFromLinks}
                                sx={{
                                    color: 'white',
                                }}
                            >
                                {translationState.importFromLinks}
                            </Button>
                        }
                        {importedState && chatBotImportState.status === 200 && chatBotImportState.data.importing !== null && chatBotImportState.data.importing.status === 'finished' &&
                            <Box
                                sx={{
                                    marginBottom: 4,
                                }}
                            >
                                <Typography variant="subtitle1">
                                    {translationState.allDataHasBeenImported}
                                </Typography>
                            </Box>
                        }
                        {(linksState.length === 0 || importedState) &&
                            <Box>
                                {previouslyImportedLinksState.status === 200 && previouslyImportedLinksState.data.links.map((link: ImportLinkModel, index: number) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={true}
                                                        disabled={true}
                                                    />
                                                }
                                                label={
                                                    <Typography>
                                                        {link.link}
                                                    </Typography>
                                                }
                                            />
                                        </FormControl>
                                    </Box>
                                ))}
                                <FormControl fullWidth>
                                    <TextField
                                        label={translationState.website}
                                        variant="outlined"
                                        value={websiteState}
                                        onChange={(e) => setWebsiteState(e.target.value)}
                                        sx={{
                                            marginBottom: 2,
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        onClick={importWebsite}
                                        sx={{
                                            color: 'white',
                                        }}
                                    >
                                        {translationState.import}
                                    </Button>
                                </FormControl>
                            </Box>
                        }
                    </Box>
                }
            </Box>
            <Typography
                variant="h6"
                sx={{
                    mt: 4,
                }}
            >
                {translationState.installInstructions}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    backgroundColor: 'white',
                    borderRadius: 4,
                    p: 4,
                    marginTop: 2,
                }}
            >
                <Typography variant="h6">
                    {translationState.insertScript}
                </Typography>
                <code
                    style={{
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                    }}
                >
                    {scriptTag}
                </code>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    backgroundColor: 'white',
                    borderRadius: 4,
                    p: 4,
                    marginTop: 2,
                }}
            >
                <Typography variant="h6">
                    WordPress
                </Typography>
                <Typography variant="body1">
                    {translationState.wordPressExplanation}
                </Typography>
            </Box>
            <Notification
                open={notificationOpenState}
                setOpen={setNotificationOpenState}
                message={notificationMessageState}
                severity={notificationSeverityState as 'info' | 'success' | 'warning' | 'error'}
            />
            <Chatbot
                chatbot={id!}
                isPreview={true}
                update={update}
            />
            <Box
                sx={{
                    height: 80,
                    width: '100%',
                }}
            >
            </Box>
        </Container>
    );
}

export default Configuration;
