import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function GetConversation2EndpointGetHook(
    param_chatId: string, 
) {
    return GetWithHook(routes.GetConversation, {
        chatId: param_chatId, 
    });
}

export function GetConversation2EndpointGetPromise(
    param_chatId: string, 
) {
    return GetWithPromise(routes.GetConversation, {
        chatId: param_chatId, 
    });
}

