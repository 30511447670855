import { ExpandMore, Message, SettingsEthernet, ShowChart } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Create from './Chatbot/Create';
import Chatbot from './generals/Chatbot';
import { theme } from './generals/theme';
import { useBlack } from './hooks/useBlack';
import LoginModal from './nav/LoginModal';
import SignupModal from './nav/SignupModal';
import { Language2EndpointGetHook } from './routes/Language';

function Home() {
    const [languageState, setLanguageState] = useState({
        wearetaiger: '',
        in5minutes: '',
        digitaleasyfast: '',
        trynow: '',
        chatbot: '',
        chatbotdescription: '',
        personalAssistant: '',
        personalAssistantdescription: '',
        taigerIsMoreThanAI: '',
        kickStartYourCompany: '',
        firstSelectTool: '',
        configureIt: '',
        integrateWithYourWebsite: '',
        performanceForYourBusiness: '',
        withoutAI: '',
        withAI: '',
        faqs: '',
        whatIsTaiger: '',
        whatIsTaigerAnswer: '',
        howIndividualIsTaiger: '',
        howIndividualIsTaigerAnswer: '',
        canTaigerReplaceHumans: '',
        canTaigerReplaceHumansAnswer: '',
        comingSoon: '',
    });

    useBlack();

    const languageResponse = Language2EndpointGetHook(JSON.stringify(Object.keys(languageState)));

    useEffect(() => {
        if (languageResponse.data !== undefined) {
            setLanguageState(languageResponse.data);
            setIsLogin(languageResponse.userState.isLogin);
        }
    }, [languageResponse.data]);

    interface GridItemProps {
        icon: any;
        title: string;
        description: string;
    }

    function GridItem(props: GridItemProps) {
        return (
            <Grid
                item
                md={4}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 4,
                }}
            >
                {props.icon}
                <Typography
                    variant="h4"
                    component="div"
                >
                    {props.title}
                </Typography>
                <Typography
                    variant="h6"
                    component="div"
                >
                    {props.description}
                </Typography>
            </Grid>
        );
    }

    const iconSx = {
        fontSize: 50,
        boxShadow: '0px 0px 100px 10px rgba(0, 0, 255, 1)',
    };

    const [isLogin, setIsLogin] = useState(false);
    const [openSignup, setOpenSignup] = useState(false);
    const [openLogin, setOpenLogin] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);

    const showSignup = () => {
        setOpenLogin(false);
        setOpenSignup(true);
    };

    const closeSignup = () => {
        setOpenSignup(false);
    };

    const showLogin = () => {
        setOpenSignup(false);
        setOpenLogin(true);
    };

    const closeLogin = () => {
        setOpenLogin(false);
    };


    return (
        <Container
            maxWidth="lg"
            sx={{
                paddingY: 5,
                color: 'black',
            }}
        >
            <Chatbot
                chatbot='original'
            />
            <Box
                sx={{
                    justifyContent: 'center',
                    textAlign: 'center',
                    marginTop: 10,
                    color: 'black',
                }}
            >
                <Typography
                    variant="h1"
                    component="div"
                    sx={{
                        fontSize: 100,
                    }}
                >
                    {languageState.wearetaiger}
                </Typography>
                <Typography
                    variant="h4"
                    component="div"
                    sx={{
                        marginTop: 25,
                        fontSize: 60,
                        textTransform: 'uppercase',
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                    }}
                >
                    {languageState.in5minutes}
                </Typography>
                <Typography
                    variant="h4"
                    component="div"
                    sx={{
                        marginTop: 2,
                        fontSize: 20,
                        textTransform: 'uppercase',
                    }}
                >
                    {languageState.digitaleasyfast}
                </Typography>
                <Button
                    variant="contained"
                    sx={{
                        marginTop: 10,
                        backgroundColor: theme.palette.primary.dark,
                        fontSize: 60,
                        borderRadius: 5,
                        paddingX: 5,
                        paddingY: 1,
                        boxShadow: '0px 0px 60px 5px rgba(0, 0, 255, 1)',
                        '&:hover': {
                            boxShadow: '0px 0px 30px 5px rgba(0, 0, 255, 1)',
                        },
                        color: 'white',
                    }}
                    onClick={() => {
                        isLogin
                            ? setOpenCreate(true)
                            : showSignup();
                    }}
                >
                    {languageState.trynow}
                </Button>
                <SignupModal
                    openSignup={openSignup}
                    closeSignup={closeSignup}
                    openLogin={showLogin}
                />
                <LoginModal
                    openLogin={openLogin}
                    closeLogin={closeLogin}
                    openSignup={showSignup}
                />
                <Create
                    open={openCreate}
                    close={() => setOpenCreate(false)}
                />
            </Box>
            {false &&
                <Box
                    sx={{
                        marginTop: 50,
                        display: 'flex',
                        textAlign: 'center',
                        color: 'black',
                    }}
                >
                    <Grid container spacing={4}>
                        <GridItem
                            icon={<Message sx={iconSx} />}
                            title={languageState.chatbot}
                            description={languageState.chatbotdescription}
                        />
                        <GridItem
                            icon={<SettingsEthernet sx={iconSx} />}
                            title={languageState.comingSoon}
                            description={''}
                        />
                    </Grid>
                </Box>
            }
            <Box
                sx={{
                    marginTop: 50,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    color: 'black',
                }}
            >
                <ShowChart
                    sx={{
                        fontSize: 100,
                        transform: 'rotate(-20deg)',
                    }}
                />
                <Typography
                    variant="h1"
                    component="div"
                    sx={{
                        fontSize: 85,
                        marginTop: 10,
                    }}
                >
                    {languageState.taigerIsMoreThanAI}
                </Typography>
                <ShowChart
                    sx={{
                        fontSize: 100,
                        marginTop: 10,
                        transform: 'rotate(-260deg)',
                    }}
                />
            </Box>
            <Box
                sx={{
                    marginTop: 30,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    color: 'black',
                }}
            >
                <Typography
                    variant="h1"
                    component="div"
                    sx={{
                        fontSize: { xs: 60, md: 85 },
                        textShadow: '0px 0px 60px rgba(0, 0, 255, 1)',
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                    }}
                >
                    {languageState.kickStartYourCompany}
                </Typography>
                <Typography
                    variant="h1"
                    component="div"
                    sx={{
                        marginTop: 10,
                        fontSize: 40,
                        ml: 5,
                    }}
                >
                    <span style={{ fontSize: 100 }}>1. </span>{languageState.firstSelectTool}
                </Typography>
                <ShowChart
                    sx={{
                        fontSize: 100,
                        marginTop: 10,
                        transform: 'rotate(-200deg)',
                        ml: 20,
                    }}
                />
                <Typography
                    variant="h1"
                    component="div"
                    sx={{
                        fontSize: { xs: 40, md: 60 },
                        ml: 20,
                    }}
                >
                    <span style={{ fontSize: 100 }}>2. </span>{languageState.configureIt}
                </Typography>
                <ShowChart
                    sx={{
                        fontSize: 100,
                        marginTop: 10,
                        transform: 'rotate(-270deg)',
                        ml: -20,
                    }}
                />
                <Typography
                    variant="h1"
                    component="div"
                    sx={{
                        fontSize: 60,
                        ml: 5,
                    }}
                >
                    <span style={{ fontSize: 100 }}>3. </span>{languageState.integrateWithYourWebsite}
                </Typography>
            </Box>
            <Box
                sx={{
                    position: 'relative',
                    marginTop: 30,
                    display: 'flex',
                    flexDirection: 'column',
                    paddingX: 5,
                    paddingBottom: 5,
                    color: 'black',
                    borderTop: '4px solid rgba(255, 255, 255, 0.3)',
                    borderRight: '4px solid rgba(255, 255, 255, 0.2)',
                    borderLeft: '4px solid rgba(255, 255, 255, 0.2)',
                    borderBottom: '4px solid rgba(255, 255, 255, 0.3)',
                    borderRadius: 5,
                    marginBottom: 30,
                    background: 'linear-gradient(0deg, rgba(0,0,50,0.5) 0%, rgba(0,0,255,0) 100%)',
                }}
            >
                <Typography
                    variant="h1"
                    component="div"
                    sx={{
                        fontSize: 50,
                        marginTop: 10,
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                    }}
                >
                    {languageState.performanceForYourBusiness}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: 'rgba(255, 0, 0, 0.2)',
                            marginTop: 2,
                            borderRadius: 2,
                            width: '100%',
                            height: 50,
                            display: 'flex',
                            alignItems: 'center',
                            paddingX: 2,
                            boxShadow: '0px 0px 20px 5px rgba(255, 0, 0, 0.5)',
                            animation: 'animate 75s ease-in-out',
                            '@keyframes animate': {
                                '0%': {
                                    width: '15%',
                                },
                                '100%': {
                                    width: '100%',
                                },
                            },
                        }}
                    >
                        <Typography
                            variant="h1"
                            component="div"
                            sx={{
                                fontSize: 30,
                            }}
                        >
                            {languageState.withoutAI}
                        </Typography>
                    </Box>
                    <Typography
                        variant="h1"
                        component="div"
                        sx={{
                            fontSize: 30,
                            marginLeft: 2,
                            width: 100,
                        }}
                    >
                        60min
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: 'rgba(0, 0, 255, 0.2)',
                            marginTop: 2,
                            borderRadius: 2,
                            width: '100%',
                            height: 50,
                            display: 'flex',
                            alignItems: 'center',
                            paddingX: 2,
                            boxShadow: '0px 0px 20px 5px rgba(0, 0, 255, 0.5)',
                            animation: 'animate2 4s ease-in-out',
                            '@keyframes animate2': {
                                '0%': {
                                    width: '15%',
                                },
                                '100%': {
                                    width: '100%',
                                },
                            },
                        }}
                    >
                        <Typography
                            variant="h1"
                            component="div"
                            sx={{
                                fontSize: 30,
                            }}
                        >
                            {languageState.withAI}
                        </Typography>
                    </Box>
                    <Typography
                        variant="h1"
                        component="div"
                        sx={{
                            fontSize: 30,
                            marginLeft: 2,
                            width: 100,
                        }}
                    >
                        3min
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    marginY: 30,
                    color: 'black',
                }}
            >
                <Box
                    sx={{
                        width: '50%',
                        height: '100%',
                    }}
                >
                    <Typography
                        variant="h1"
                        component="div"
                        sx={{
                            fontSize: 50,
                        }}
                    >
                        {languageState.faqs}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: '50%',
                        height: '100%',
                    }}
                >
                    <Accordion
                        sx={{
                            backgroundColor: 'black',
                            border: '1px solid rgba(0, 0, 255, 0.2)',
                            borderRadius: 5,
                            boxShadow: '0px 0px 5px 2px rgba(0, 0, 255, 0.5)',
                            color: 'white',
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore sx={{ color: 'black' }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography
                                variant="h1"
                                component="div"
                                sx={{
                                    fontSize: 30,
                                }}
                            >
                                {languageState.whatIsTaiger}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography
                                variant="h1"
                                component="div"
                                sx={{
                                    fontSize: 20,
                                }}
                            >
                                {languageState.whatIsTaigerAnswer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        sx={{
                            backgroundColor: 'black',
                            border: '1px solid rgba(0, 0, 255, 0.2)',
                            borderRadius: 5,
                            boxShadow: '0px 0px 5px 2px rgba(0, 0, 255, 0.5)',
                            color: 'white',
                            marginTop: 2,
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore sx={{ color: 'black' }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography
                                variant="h1"
                                component="div"
                                sx={{
                                    fontSize: 30,
                                }}
                            >
                                {languageState.canTaigerReplaceHumans}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography
                                variant="h1"
                                component="div"
                                sx={{
                                    fontSize: 20,
                                }}
                            >
                                {languageState.canTaigerReplaceHumansAnswer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        sx={{
                            backgroundColor: 'black',
                            border: '1px solid rgba(0, 0, 255, 0.2)',
                            borderRadius: 5,
                            boxShadow: '0px 0px 5px 2px rgba(0, 0, 255, 0.5)',
                            color: 'white',
                            marginTop: 2,
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore sx={{ color: 'black' }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography
                                variant="h1"
                                component="div"
                                sx={{
                                    fontSize: 30,
                                }}
                            >
                                {languageState.howIndividualIsTaiger}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography
                                variant="h1"
                                component="div"
                                sx={{
                                    fontSize: 20,
                                }}
                            >
                                {languageState.howIndividualIsTaigerAnswer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Box>
        </Container >
    );
}

export default Home;
