import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function EditChatbot2EndpointGetHook(
    param_chatbot: string, 
    param_title: string, 
    param_description: string, 
    param_initialMessage: string, 
    param_model: string, 
    param_instructions: string, 
    param_logMessages: string, 
    param_enabled: string, 
    param_primaryColor: string, 
    param_secondaryColor: string, 
) {
    return GetWithHook(routes.EditChatbot, {
        chatbot: param_chatbot, 
        title: param_title, 
        description: param_description, 
        initialMessage: param_initialMessage, 
        model: param_model, 
        instructions: param_instructions, 
        logMessages: param_logMessages, 
        enabled: param_enabled, 
        primaryColor: param_primaryColor, 
        secondaryColor: param_secondaryColor, 
    });
}

export function EditChatbot2EndpointGetPromise(
    param_chatbot: string, 
    param_title: string, 
    param_description: string, 
    param_initialMessage: string, 
    param_model: string, 
    param_instructions: string, 
    param_logMessages: string, 
    param_enabled: string, 
    param_primaryColor: string, 
    param_secondaryColor: string, 
) {
    return GetWithPromise(routes.EditChatbot, {
        chatbot: param_chatbot, 
        title: param_title, 
        description: param_description, 
        initialMessage: param_initialMessage, 
        model: param_model, 
        instructions: param_instructions, 
        logMessages: param_logMessages, 
        enabled: param_enabled, 
        primaryColor: param_primaryColor, 
        secondaryColor: param_secondaryColor, 
    });
}

export function EditChatbot2EndpointPostPromise(
    param_chatbot: string, 
    param_title: string, 
    param_description: string, 
    param_initialMessage: string, 
    param_model: string, 
    param_instructions: string, 
    param_logMessages: string, 
    param_enabled: string, 
    param_primaryColor: string, 
    param_secondaryColor: string, 
) {
    return PostWithPromise(routes.EditChatbotPost, {
        chatbot: param_chatbot, 
        title: param_title, 
        description: param_description, 
        initialMessage: param_initialMessage, 
        model: param_model, 
        instructions: param_instructions, 
        logMessages: param_logMessages, 
        enabled: param_enabled, 
        primaryColor: param_primaryColor, 
        secondaryColor: param_secondaryColor, 
    });
}

