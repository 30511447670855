import { theme } from '../generals/theme';

import { Box, Button, FormControl, Modal, TextField, Typography } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import useTranslationState from '../generals/useTranslationState';
import { Verify2EndpointPostPromise } from '../routes/Verify';

interface VerifyModalProps {
    openVerify: boolean;
    closeVerifyModal: () => void;
}

function VerifyModal({ openVerify, closeVerifyModal }: VerifyModalProps) {
    const translationState = useTranslationState({
        verify: '',
        verifyMail: '',
        token: '',
    });

    const navigate = useNavigate();

    const handleVerify = () => {
        const token = document.getElementById('tokenField') as HTMLInputElement;

        const promise = Verify2EndpointPostPromise('', '', token.value);

        promise.then((value: any) => {
            if (value.status === 200) {
                value.json().then((data: any) => {
                    if (data.data.verified === true) {
                        localStorage.setItem('hash', data.data.hash);
                        navigate('/');
                        window.location.reload();
                        closeVerifyModal();
                    }
                });
            }
        });
    };

    const style = {
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid ' + theme.palette.primary.main,
        boxShadow: 24,
        p: 4,
        width: { xs: '80%', sm: '50%', md: 300 },
        outline: 'none',
    };

    return (
        <div>
            <Modal
                open={openVerify}
                onClose={closeVerifyModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            p: 1,
                        }}
                    >
                        <CloseOutlined
                            onClick={closeVerifyModal}
                            sx={{
                                cursor: 'pointer',
                                color: theme.palette.primary.main,
                            }}
                        />
                    </Box>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {translationState.verify}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {translationState.verifyMail}
                    </Typography>
                    <FormControl fullWidth variant="outlined">
                        <TextField
                            id="tokenField"
                            name="token"
                            type="text"
                            autoComplete="token"
                            label={translationState.token}
                            variant="standard"
                            onKeyUp={(ev) => {
                                if (ev.key === 'Enter') {
                                    handleVerify();
                                    ev.preventDefault();
                                }
                            }}
                        />
                        <Button
                            variant="outlined"
                            sx={{
                                my: 1,
                                mx: 1.5,
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.main,
                                    color: 'white',
                                },
                            }}
                            onClick={handleVerify}
                        >
                            {translationState.verify}
                        </Button>
                    </FormControl>
                </Box>
            </Modal>
        </div>
    );
}

export default VerifyModal;
