import { CloseOutlined } from '@mui/icons-material';
import { Box } from '@mui/system';
import { theme } from './theme';

interface CloseButtonProps {
    close: () => void;
}

function CloseButton({ close }: CloseButtonProps) {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                p: 1,
            }}
        >
            <CloseOutlined
                onClick={close}
                sx={{
                    cursor: 'pointer',
                    color: theme.palette.primary.light,
                }}
            />
        </Box>
    );
}


export default CloseButton;
