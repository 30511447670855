import { Box, Typography } from '@mui/material';

function ErrorChatbot() {
    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                right: { xs: 5, md: 25 },
            }}
        >
            <Typography variant="h6" color="error">
                Error. Please try again.
            </Typography>
        </Box>
    );
}

export default ErrorChatbot;
