import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function ImportedLinks2EndpointGetHook(
    param_chatbot: string, 
) {
    return GetWithHook(routes.ImportedLinks, {
        chatbot: param_chatbot, 
    });
}

export function ImportedLinks2EndpointGetPromise(
    param_chatbot: string, 
) {
    return GetWithPromise(routes.ImportedLinks, {
        chatbot: param_chatbot, 
    });
}

