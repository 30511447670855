import { CloseOutlined } from '@mui/icons-material';
import { Button, FormControl, Link, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import HorizontalRule from '../generals/HorizontalRule';
import Modal from '../generals/Modal';
import { modalStyle } from '../generals/modalstyle';
import { theme } from '../generals/theme';
import useTranslationState from '../generals/useTranslationState';
import { Login2EndpointPostPromise } from '../routes/Login';
import PINModal from './PINModal';

interface SignupModalProps {
    openLogin: boolean;
    closeLogin: () => void;
    openSignup: () => void;
}

export default function LoginModal({ openLogin, closeLogin, openSignup }: SignupModalProps) {
    const translationState = useTranslationState({
        skills: '',
        skillsDescription: '',
        fullname: '',
        email: '',
        signup: '',
        login: '',
        verifyMail: '',
        normalPin: '',
        dontHaveAnAccount: '',
        pin: '',
        pinText: '',
        verify: '',
    });

    const [emailState, setEmailState] = useState('');

    function handleLogin() {
        const promise = Login2EndpointPostPromise(emailState);

        promise.then((value: any) => {
            if (value.status === 200) {
                value.json().then((data: any) => {
                    setOpenPIN(true);
                });
            }
        });

        closeLogin();
    }

    const [openPIN, setOpenPIN] = useState(false);

    const closePINModal = () => {
        setOpenPIN(false);
    };

    const style = {
        ...modalStyle,
        maxWidth: 275,
    };

    return (
        <div>
            <Modal
                open={openLogin}
                close={closeLogin}
                sx={style}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        p: 1,
                    }}
                >
                    <CloseOutlined
                        onClick={closeLogin}
                        sx={{
                            cursor: 'pointer',
                            color: theme.palette.primary.main,
                        }}
                    />
                </Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Login
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                        <TextField
                            id="emaillogin"
                            name="email"
                            type="email"
                            autoComplete="email"
                            value={emailState}
                            onChange={(ev) => setEmailState(ev.target.value)}
                            onKeyUp={(ev) => {
                                if (ev.key === 'Enter') {
                                    handleLogin();
                                    ev.preventDefault();
                                }
                            }}
                            label={translationState.email}
                            variant="standard"
                            color="primary"
                        />
                        <Button
                            variant="outlined"
                            sx={{
                                my: 1,
                                mx: 1.5,
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.main,
                                    color: 'white',
                                },
                            }}
                            onClick={handleLogin}
                        >
                            {translationState.login}
                        </Button>
                    </FormControl>
                </Typography>
                <HorizontalRule />
                <Typography variant="caption" component="div" sx={{ mt: 1 }}>
                    {translationState.dontHaveAnAccount}
                    <Link
                        href="#"
                        onClick={() => {
                            closeLogin();
                            openSignup();
                        }}
                        sx={{
                            color: theme.palette.primary.light,
                            '&:hover': {
                                color: theme.palette.primary.light,
                                textDecoration: 'underline',
                            },
                            marginLeft: 1,
                        }}
                    >
                        {translationState.signup}
                    </Link>
                </Typography>
            </Modal>
            <PINModal
                open={openPIN}
                close={closePINModal}
                translationsState={translationState}
                email={emailState}
            />
        </div>
    );
}


