import { createTheme } from '@mui/material';

export const theme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Rubik',
                }
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input': {
                        color: '#1e4bb3',
                    },
                    '& .MuiInputBase-input::placeholder': {
                        color: '#1e4bb3',
                    },
                    '& .MuiInputLabel-root': {
                        color: '#1e4bb3',
                    },
                },
            },
        },
    },
    palette: {
        divider: '#666666',
        mode: 'light',
        primary: {
            light: '#AEBDF4',
            main: '#3277e5',
            dark: '#1e4bb3',
            contrastText: 'white',
        },
        text: {
            primary: '#0a0a0a',
            secondary: '#00000a',
            disabled: '#bbbbbb',
        },
        secondary: {
            light: '#F9A3B0',
            main: '#EC5272',
            dark: '#AE0843',
        },
        background: {
            default: '#ffffff',
            paper: '#ffffff',
        },
        error: {
            main: '#ba000d',
        },
    }
});
