import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function GetChatbot2EndpointGetHook(
    param_chatbot: string, 
) {
    return GetWithHook(routes.GetChatbot, {
        chatbot: param_chatbot, 
    });
}

export function GetChatbot2EndpointGetPromise(
    param_chatbot: string, 
) {
    return GetWithPromise(routes.GetChatbot, {
        chatbot: param_chatbot, 
    });
}

