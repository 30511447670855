import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function ClearChat2EndpointGetHook(
    param_chatbot: string, 
) {
    return GetWithHook(routes.ClearChat, {
        chatbot: param_chatbot, 
    });
}

export function ClearChat2EndpointGetPromise(
    param_chatbot: string, 
) {
    return GetWithPromise(routes.ClearChat, {
        chatbot: param_chatbot, 
    });
}

export function ClearChat2EndpointPostPromise(
    param_chatbot: string, 
) {
    return PostWithPromise(routes.ClearChatPost, {
        chatbot: param_chatbot, 
    });
}

