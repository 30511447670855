import { Box, Container, Grid, Link, Pagination, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useEndpoint from './generals/useEndpoint';
import useTranslationState from './generals/useTranslationState';
import { useBlack } from './hooks/useBlack';
import { ChatbotModel } from './models/ChatbotModel';
import ListChatbotsResponse from './responses/ListChatbots';
import { ListChatbots2EndpointGetHook } from './routes/ListChatbots';

function ConfigurationOverview() {
    const navigate = useNavigate();

    const translationState = useTranslationState({
        noChatbots: '',
        yourChatbots: '',
        adminChatbots: '',
    });

    const [chatbotsState, _setChatbotsState] = useEndpoint<ListChatbotsResponse>(ListChatbots2EndpointGetHook());

    const [pageState, setPageState] = useState<number>(1);
    const perPage = 8;

    useBlack();

    const getChatbotsForPage = () => chatbotsState.data.chatbots.slice((pageState - 1) * perPage, pageState * perPage);

    return (
        <Container
            maxWidth="lg"
            sx={{
                paddingBottom: 10,
            }}
        >
            {chatbotsState.status === 200 && chatbotsState.data.chatbots.length > 0 &&
                <Box
                    sx={{
                        mt: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h4" color="black" sx={{ mb: 2 }}>
                        {translationState.yourChatbots}
                    </Typography>
                    <Grid container spacing={2}>
                        {chatbotsState.status === 200 && getChatbotsForPage().map((chatbot: ChatbotModel) =>
                            <Grid item xs={12} sm={6} md={4} lg={3} key={chatbot.id}>
                                <Link
                                    href={`/config/${chatbot.id}`}
                                    underline="none"
                                    color="inherit"
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: 'white',
                                            boxShadow: '0 0 5px 5px rgba(220, 220, 220, 0.5)',
                                            height: 200,
                                            borderRadius: 4,
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            py: 2,
                                        }}
                                    >
                                        <Typography variant="h4" sx={{ textAlign: 'center' }}>
                                            {chatbot.title}
                                        </Typography>
                                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                                            {chatbot.description}
                                        </Typography>
                                    </Box>
                                </Link>
                            </Grid>
                        )}
                    </Grid>
                    {chatbotsState.data.chatbots.length > perPage &&
                        <Pagination
                            count={Math.ceil(chatbotsState.data.chatbots.length / perPage)}
                            page={pageState}
                            onChange={(_event, value) => setPageState(value)}
                            color="primary"
                            sx={{
                                mt: 4,
                            }}
                        />
                    }
                </Box>
            }
            {chatbotsState.status === 200 && chatbotsState.data.chatbotsAdmin.length > 0 &&
                <Box
                    sx={{
                        mt: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h4" color="black">
                        {translationState.adminChatbots}
                    </Typography>
                    <Grid container spacing={2}>
                        {chatbotsState.status === 200 && chatbotsState.data.chatbotsAdmin.map((chatbot: ChatbotModel) =>
                            <Grid item xs={12} sm={6} md={4} lg={3} key={chatbot.id}>
                                <Link
                                    href={`/config/${chatbot.id}`}
                                    underline="none"
                                    color="inherit"
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: 'white',
                                            boxShadow: '0 0 5px 5px rgba(220, 220, 220, 0.5)',
                                            height: 200,
                                            borderRadius: 4,
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            py: 2,
                                        }}
                                    >
                                        <Typography variant="h4">
                                            {chatbot.title}
                                        </Typography>
                                        <Typography variant="body1">
                                            {chatbot.description}
                                        </Typography>
                                    </Box>
                                </Link>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            }
            {chatbotsState.status === 200 && chatbotsState.data.chatbots.length === 0 &&
                <Box
                    sx={{
                        mt: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h4">
                        {translationState.noChatbots}
                    </Typography>
                </Box>
            }
        </Container>
    );
}

export default ConfigurationOverview;
