import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function ListConversations2EndpointGetHook(
    param_chatbot: string, 
) {
    return GetWithHook(routes.ListConversations, {
        chatbot: param_chatbot, 
    });
}

export function ListConversations2EndpointGetPromise(
    param_chatbot: string, 
) {
    return GetWithPromise(routes.ListConversations, {
        chatbot: param_chatbot, 
    });
}

