import { Box, Typography } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useState } from 'react';
import { GridViewOutlined, House } from '@mui/icons-material';
import { getCurrentPage } from '../modules/api/client';
import { theme } from '../generals/theme';

interface Props {
    isLogin: boolean;
    translations: any;
    showSignup: () => void;
    showLogin: () => void;
    gotoHome: () => void;
    gotoCreate: () => void;
    gotoBots: () => void;
}

function MobileBottomNav(props: Props) {
    const [bottomNavValue, setBottomNavValue] = useState(0);

    const getIconColor = (page: string) => getCurrentPage() === page ? theme.palette.primary.dark : theme.palette.primary.main;

    const getLabelColor = (page: string) => getCurrentPage() === page ? theme.palette.primary.dark : theme.palette.primary.main;

    const getBackgroundColor = (page: string) => getCurrentPage() === page ? 'rgba(0, 0, 0, 0.05)' : 'transparent';

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                width: 1,
                height: 'auto',
                display: { xs: 'block', md: 'none' },
                zIndex: 1003,
            }}
        >
            <BottomNavigation
                showLabels
                value={bottomNavValue}
                onChange={(_event: any, newValue) => {
                    setBottomNavValue(newValue);
                }}
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    color: theme.palette.primary.dark,
                    borderTop: '1px solid rgba(255, 255, 255, 0.18)',
                    backgroundColor: 'rgba(255, 255, 255, 0.6)',
                    backdropFilter: 'blur(30px)',
                    pb: 2,
                }}
            >
                <BottomNavigationAction
                    sx={{
                        backgroundColor: getBackgroundColor('home'),
                        borderRadius: 4,
                    }}
                    label={
                        <Typography
                            sx={{
                                color: getLabelColor('home'),
                            }}
                        >
                            {props.translations.home}
                        </Typography>
                    }
                    icon={
                        <House
                            sx={{
                                color: getIconColor('home'),
                            }}
                        />
                    }
                    onClick={props.gotoHome}
                />
                {!props.isLogin &&
                    [
                        <BottomNavigationAction
                            sx={{
                                backgroundColor: getBackgroundColor('login'),
                                borderRadius: 4,
                            }}
                            label={
                                <Typography
                                    sx={{
                                        color: getLabelColor('login')
                                    }}
                                >
                                    {props.translations.login}
                                </Typography>
                            }
                            icon={
                                <LoginIcon
                                    sx={{
                                        color: getIconColor('login')
                                    }}
                                />
                            }
                            onClick={props.showLogin}
                            key="login"
                        />,
                        <BottomNavigationAction
                            sx={{
                                backgroundColor: getBackgroundColor('signup'),
                                borderRadius: 4,
                            }}
                            label={
                                <Typography
                                    sx={{
                                        color: getLabelColor('signup')
                                    }}
                                >
                                    {props.translations.signup}
                                </Typography>
                            }
                            icon={
                                <PersonAddIcon
                                    sx={{
                                        color: getIconColor('signup')
                                    }}
                                />
                            }
                            onClick={props.showSignup}
                            key="signup"
                        />
                    ]
                }
                {props.isLogin &&
                    <BottomNavigationAction
                        sx={{
                            backgroundColor: getBackgroundColor('overview'),
                            borderRadius: 4,
                        }}
                        label={
                            <Typography
                                sx={{
                                    color: getLabelColor('overview'),
                                }}
                            >
                                {props.translations.overview}
                            </Typography>
                        }
                        icon={
                            <GridViewOutlined
                                sx={{
                                    color: getIconColor('overview'),
                                }}
                            />
                        }
                        onClick={props.gotoBots}
                        key="tools"
                    />
                }
                {props.isLogin &&
                    <BottomNavigationAction
                        sx={{
                            backgroundColor: getBackgroundColor('create'),
                            borderRadius: 4,
                        }}
                        label={
                            <Typography
                                sx={{
                                    color: getLabelColor('create'),
                                }}>
                                {props.translations.create}
                            </Typography>
                        }
                        icon={
                            <AddCircleOutlineIcon
                                sx={{
                                    color: getIconColor('create'),
                                }}
                            />
                        }
                        onClick={props.gotoCreate}
                        key="create"
                    />
                }
            </BottomNavigation>
        </Box>
    );
}

export default MobileBottomNav;

