import { theme } from '../generals/theme';

export const modalStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid ' + theme.palette.primary.main,
    boxShadow: 24,
    p: 4,
    width: { xs: '80vw', sm: '50vw', md: 450 },
    maxWidth: '70vw',
    maxHeight: '82vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    outline: 'none',
};

