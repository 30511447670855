import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import Message from './generals/Message';
import useFrequentEndpoint from './generals/useFrequentEndpoint';
import { ChatMessageLogModel } from './models/ChatMessageLogModel';
import EmptyExample from './responses/EmptyExample';
import GetConversationResponse from './responses/GetConversation';
import { GetConversation2EndpointGetHook, GetConversation2EndpointGetPromise } from './routes/GetConversation';

interface Props {
    chatId: string;
    translationState: any;
}

function Conversation(props: Props) {
    const [conversationState, setConversationState] = useFrequentEndpoint<GetConversationResponse, any>(GetConversation2EndpointGetHook(props.chatId), GetConversation2EndpointGetPromise, 5, props.chatId);

    useEffect(() => {
        const promise = GetConversation2EndpointGetPromise(props.chatId);
        setConversationState(EmptyExample);

        promise.then((response) => {
            setConversationState(response);
        });
    }, [props.chatId]);

    function convertMessagesToMessages(messages: ChatMessageLogModel[]) {
        return messages.map((message: ChatMessageLogModel) => ({
            id: message.id,
            role: message.role,
            content: message.message,
        }));
    }

    return (
        <>
            {conversationState.status === 200 &&
                <>
                    <Box
                        sx={{
                            width: '100%',
                            height: 30,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 5,
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        }}
                    >
                        <Typography variant="caption">
                            {props.translationState.conversation} - {conversationState.data.ip}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            overflowY: 'scroll',
                            height: 'calc(100% - 30px)',
                            px: 1,
                        }}
                    >
                        {convertMessagesToMessages(conversationState.data.messages).map((message: any, index: number) => (
                            <Message
                                key={message.id}
                                message={message}
                                index={index}
                                chatbotTitle={conversationState.data.chatbot.title}
                                primaryColor={conversationState.data.chatbot.primaryColor}
                                secondaryColor={conversationState.data.chatbot.secondaryColor}
                                reverse={true}
                            />
                        ))}
                    </Box>
                </>
            }
        </>
    );
}

export default Conversation;
