import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function Import2EndpointGetHook(
    param_chatbot: string, 
    param_website: string, 
    param_websites: string, 
) {
    return GetWithHook(routes.Import, {
        chatbot: param_chatbot, 
        website: param_website, 
        websites: param_websites, 
    });
}

export function Import2EndpointGetPromise(
    param_chatbot: string, 
    param_website: string, 
    param_websites: string, 
) {
    return GetWithPromise(routes.Import, {
        chatbot: param_chatbot, 
        website: param_website, 
        websites: param_websites, 
    });
}

export function Import2EndpointPostPromise(
    param_chatbot: string, 
    param_website: string, 
    param_websites: string, 
) {
    return PostWithPromise(routes.ImportPost, {
        chatbot: param_chatbot, 
        website: param_website, 
        websites: param_websites, 
    });
}

