import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function SetLanguage2EndpointGetHook(
    param_language: string, 
) {
    return GetWithHook(routes.SetLanguage, {
        language: param_language, 
    });
}

export function SetLanguage2EndpointGetPromise(
    param_language: string, 
) {
    return GetWithPromise(routes.SetLanguage, {
        language: param_language, 
    });
}

export function SetLanguage2EndpointPostPromise(
    param_language: string, 
) {
    return PostWithPromise(routes.SetLanguagePost, {
        language: param_language, 
    });
}

