import { CloseOutlined } from '@mui/icons-material';
import { Button, FormControl, Link, Modal, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import HorizontalRule from '../generals/HorizontalRule';
import Notification from '../generals/notification';
import { theme } from '../generals/theme';
import useTranslationState from '../generals/useTranslationState';
import { Signup2EndpointPostPromise } from '../routes/Signup';
import VerifyModal from './VerifyModal';

interface SignupModalProps {
    openSignup: boolean;
    closeSignup: () => void;
    openLogin: () => void;
}

function SignupModal({ openSignup, closeSignup, openLogin }: SignupModalProps) {
    const [pageState, setPageState] = useState(1);

    const [pinState, setPinState] = useState('');

    const [fullnameState, setFullnameState] = useState('');
    const [emailState, setEmailState] = useState('');

    const nextPage = () => {
        if (pageState === 1) {
            if (fullnameState === '' || emailState === '') {
                setNotificationMessage(translationState.fillAllFields);
                setSeverityState('error');
                showNotification();
                return;
            }

            setPageState(pageState + 1);
            handleSignup();
            closeSignup();
            setOpenVerify(true);
        }
    };

    const closeSignupWrapper = () => {
        setPageState(1);
        closeSignup();
    };

    const translationState = useTranslationState({
        skills: '',
        skillsDescription: '',
        upload: '',
        fullname: '',
        email: '',
        uploadFilesToYourProfile: '',
        signup: '',
        alreadyHaveAccount: '',
        pin: '',
        createPin: '',
        login: '',
        verifyMail: '',
        fillAllFields: '',
        skip: '',
        next: '',
        languagesDoYouSpeak: '',
    });

    function handleSignup() {
        const promise = Signup2EndpointPostPromise(
            fullnameState,
            emailState,
            '1',
            pinState,
        );

        promise.then((value: any) => {
            value.json();
        });
    }

    const [OpenNotification, setOpenNotification] = useState(false);

    const showNotification = () => {
        setOpenNotification(true);
    };

    const [notificationMessage, setNotificationMessage] = useState('');
    const [severityState, setSeverityState] = useState<'success' | 'info' | 'warning' | 'error' | undefined>('success');

    const [openVerify, setOpenVerify] = useState(false);

    const closeVerifyModal = () => {
        setOpenVerify(false);
    };


    const style = {
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid ' + theme.palette.primary.main,
        boxShadow: 24,
        p: 4,
        width: { xs: '80%', sm: '50%', md: 300 },
        outline: 'none',
    };


    return (
        <div>
            <Modal
                open={openSignup}
                onClose={closeSignupWrapper}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            p: 1,
                        }}
                    >
                        <CloseOutlined
                            onClick={closeSignupWrapper}
                            sx={{
                                cursor: 'pointer',
                                color: theme.palette.primary.main,
                            }}
                        />
                    </Box>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {translationState.signup}
                    </Typography>
                    {pageState === 1 &&
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <FormControl fullWidth sx={{ marginBottom: 2 }} >
                                <TextField
                                    id="fullnamesignup"
                                    name="fullname"
                                    label={translationState.fullname}
                                    autoComplete="fullname"
                                    variant="standard"
                                    value={fullnameState}
                                    onChange={(e) => { setFullnameState(e.target.value); }}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginBottom: 2 }} >
                                <TextField
                                    id="emailsignup"
                                    name="email"
                                    type="email"
                                    label={translationState.email}
                                    autoComplete="email"
                                    variant="standard"
                                    value={emailState}
                                    onChange={(e) => { setEmailState(e.target.value); }}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginBottom: 2 }} >
                                <TextField
                                    id="pin"
                                    name="pin"
                                    label={translationState.createPin}
                                    autoComplete="new-password"
                                    variant="standard"
                                    onChange={(e) => { setPinState(e.target.value); }}
                                    value={pinState}
                                    type="password"
                                />
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    sx={{
                                        my: 1,
                                        mx: 1.5,
                                        '&:hover': {
                                            backgroundColor: theme.palette.primary.main,
                                        },
                                    }}
                                    onClick={() => { nextPage(); }}
                                    disabled={pinState.length < 8}
                                >
                                    {translationState.next}
                                </Button>
                            </FormControl>
                        </Typography>
                    }
                    <HorizontalRule />
                    <Typography variant="caption" component="div" sx={{ mt: 1 }}>
                        {translationState.alreadyHaveAccount}
                        <Link
                            href="#"
                            onClick={() => {
                                closeSignupWrapper();
                                openLogin();
                            }}
                            sx={{
                                color: theme.palette.primary.light,
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                                marginLeft: 1,
                            }}
                        >
                            {translationState.login}
                        </Link>
                    </Typography>
                </Box>
            </Modal>
            <VerifyModal
                openVerify={openVerify}
                closeVerifyModal={closeVerifyModal}
            />
            <Notification
                setOpen={setOpenNotification}
                open={OpenNotification}
                message={notificationMessage}
                severity={severityState}
            />
        </div>
    );
}

export default SignupModal;
