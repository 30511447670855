import { AppBar, Box, Button, Link, MenuItem, Select, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Create from '../Chatbot/Create';
import useIsMobile from '../hooks/useIsMobile';
import { getCurrentPage } from '../modules/api/client';
import LoginModal from '../nav/LoginModal';
import MobileBottomNav from '../nav/MobileBottomNav';
import SignupModal from '../nav/SignupModal';
import { Language2EndpointGetHook } from '../routes/Language';
import { SetLanguage2EndpointPostPromise } from '../routes/SetLanguage';
import './nav.css';
import { theme } from './theme';

function Nav() {
    const navigate = useNavigate();

    const [languageState, setLanguageState] = useState({
        home: '',
        prices: '',
        config: '',
        about: '',
        login: '',
        trynow: '',
        overview: '',
        signup: '',
        create: '',
        userLanguageSetting: '',
    });
    const isMobile = useIsMobile();

    const [language, setLanguage] = useState('');

    useEffect(() => {
        if (languageState.userLanguageSetting !== '') {
            setLanguage(languageState.userLanguageSetting);
        }
    }, [languageState]);

    useEffect(() => {
        if (language === '' || language === languageState.userLanguageSetting) {
            return;
        }

        const promise = SetLanguage2EndpointPostPromise(language);

        promise.then(() => {
            navigate(0);
        });
    }, [language]);

    const languageResponse = Language2EndpointGetHook(JSON.stringify(Object.keys(languageState)));

    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        if (languageResponse.status === 200 && languageResponse.data !== undefined) {
            setLanguageState(languageResponse.data);
            setIsLogin(languageResponse.userState.isLogin);
        }
    }, [languageResponse]);

    interface NavLinkProps {
        href: string;
        text: string;
    }

    function NavLink(props: NavLinkProps) {
        return (
            <Link
                href={props.href}
                underline="none"
                sx={{
                    fontSize: 20,
                }}
            >
                {props.text}
            </Link>
        );
    }

    const [openCreate, setOpenCreate] = useState(false);

    const [openSignup, setOpenSignup] = useState(false);
    const [openLogin, setOpenLogin] = useState(false);

    const showSignup = () => {
        if (isLogin) {
            setOpenCreate(true);
            return;
        }

        setOpenLogin(false);
        setOpenSignup(true);
    };

    const closeSignup = () => {
        setOpenSignup(false);
    };

    const showLogin = () => {
        setOpenSignup(false);
        setOpenLogin(true);
    };

    const closeLogin = () => {
        setOpenLogin(false);
    };

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    height: 80,
                    alignItems: 'center',
                    paddingX: { xs: 4, md: 10 },
                    backgroundColor: 'transparent',
                    backdropFilter: 'blur(20px)',
                    borderBottom: '1px solid rgba(255, 255, 255, 0.18)',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 4,
                    }}
                >
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 'bold',
                        }}
                    >
                        tAIger
                    </Typography>
                    {!isMobile &&
                        <Box
                            sx={{
                                borderRadius: 25,
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                height: 40,
                                paddingX: 4,
                            }}
                        >
                            <Button
                                sx={{
                                    fontSize: 20,
                                    '&:hover > div': {
                                        width: '100%',
                                        borderRadius: 25,
                                        transition: 'width 0.3s ease-in-out',
                                    },
                                    '> div': {
                                        transition: 'width 0.3s ease-in-out',
                                    },
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate('/')}
                            >
                                {languageState.home}
                                {getCurrentPage() === 'home' &&
                                    <Box
                                        sx={{
                                            width: 5,
                                            height: 5,
                                            borderRadius: 25,
                                            backgroundColor: theme.palette.primary.main,
                                            position: 'absolute',
                                            bottom: 0,
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                        }}
                                    />
                                }
                            </Button>
                            {isLogin &&
                                <Button
                                    sx={{
                                        fontSize: 20,
                                        '&:hover > div': {
                                            width: '100%',
                                            borderRadius: 25,
                                            transition: 'width 0.3s ease-in-out',
                                        },
                                        '> div': {
                                            transition: 'width 0.3s ease-in-out',
                                        },
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => navigate('/config')}
                                >
                                    {languageState.overview}
                                    {getCurrentPage() === 'config' &&
                                        <Box
                                            sx={{
                                                width: 5,
                                                height: 5,
                                                borderRadius: 25,
                                                backgroundColor: theme.palette.primary.main,
                                                position: 'absolute',
                                                bottom: 0,
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                            }}
                                        />
                                    }
                                </Button>
                            }
                        </Box>
                    }
                </Box>
                <Box>
                    {!isLogin &&
                        <Button
                            variant="text"
                            sx={{
                                ml: 2,
                            }}
                            onClick={showLogin}
                        >
                            {languageState.login}
                        </Button>
                    }
                    <Button
                        variant="outlined"
                        sx={{
                            ml: 2,
                            border: '1px solid rgba(180, 0, 255, 0.68)',
                            boxShadow: 'inset 0 0 20px rgba(180, 0, 255, 0.68)',
                        }}
                        onClick={showSignup}
                    >
                        {isLogin ? languageState.create : languageState.trynow}
                    </Button>
                    <Select
                        variant="standard"
                        value={language}
                        onChange={(event) => setLanguage(event.target.value)}
                        sx={{
                            ml: { xs: 2, md: 4 },
                        }}
                    >
                        <MenuItem value="english">English</MenuItem>
                        <MenuItem value="german">Deutsch</MenuItem>
                        <MenuItem value="french">Français</MenuItem>
                        <MenuItem value="spanish">Español</MenuItem>
                        <MenuItem value="italian">Italiano</MenuItem>
                        <MenuItem value="dutch">Nederlands</MenuItem>
                        <MenuItem value="portuguese">Português</MenuItem>
                    </Select>
                </Box>
            </AppBar>
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    height: '100%',
                    background: theme.palette.grey[300],
                    zIndex: -1,
                    opacity: 0.7,
                    animation: 'fade 10s linear infinite',
                }}
            >
            </Box>
            <MobileBottomNav
                isLogin={isLogin}
                translations={languageState}
                showSignup={showSignup}
                showLogin={showLogin}
                gotoHome={() => navigate('/')}
                gotoCreate={() => setOpenCreate(true)}
                gotoBots={() => navigate('/config')}
            />
            <Box
                sx={{
                    height: isMobile ? 56 : 93,
                    width: '100%',
                }}
            >
            </Box>
            <SignupModal
                openSignup={openSignup}
                closeSignup={closeSignup}
                openLogin={showLogin}
            />
            <LoginModal
                openLogin={openLogin}
                closeLogin={closeLogin}
                openSignup={showSignup}
            />
            <Create
                open={openCreate}
                close={() => setOpenCreate(false)}
            />
        </>
    );
}

export default Nav;
