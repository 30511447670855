import { useEffect } from 'react';

export const useBlack = () => {
    useEffect(() => {
        document.body.style.backgroundColor = 'white';

        return () => {
            document.body.style.backgroundColor = '';
        };
    }, []);
};
