const EmptyExample = {
    status: 102,
    responseMessage: '',
    route: '',
    userState: {
        userModel: {
            id: 0,
            name: '',
            email: '',
            description: '',
            image: '',
            language: '',
            role: '',
            lastonline: '',
        },
        isLogin: false,
    },
    data: {
    },
    responseTime: 0,
    trace: {},
    sessId: '',
    params: {},
    profiler: {},
    queries: {},
    ab: '',
    retries: 0,
};

export default EmptyExample;

