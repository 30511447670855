import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Home';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Error from './Error';
import { ThemeProvider } from '@mui/material';
import { theme } from './generals/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Nav from './generals/nav';
import Configuration from './Configuration';
import ConfigurationOverview from './ConfigurationOverview';
import LoginCheck from './generals/LoginCheck';
import ChatbotWrapper from './Chatbot/ChatbotWrapper';
import ConversationCenter from './ConversationCenter';
import ErrorChatbot from './Chatbot/ErrorChatbot';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: '/',
        element: <>
            <Nav />
            <Home />
        </>,
        errorElement: <Error />,
    },
    {
        path: '/home',
        element: <>
            <Nav />
            <Home />
        </>,
        errorElement: <Error />,
    },
    {
        path: '/config',
        element: <>
            <Nav />
            <LoginCheck>
                <ConfigurationOverview />
            </LoginCheck>
        </>,
        errorElement: <Error />,
    },
    {
        path: '/config/:id',
        element: <>
            <Nav />
            <LoginCheck>
                <Configuration />
            </LoginCheck>
        </>,
        errorElement: <Error />,
    },
    {
        path: '/conversations/:id',
        element: <>
            <Nav />
            <LoginCheck>
                <ConversationCenter />
            </LoginCheck>
        </>,
        errorElement: <Error />,
    },
    {
        path: '/fullscreen/:id',
        element: <ChatbotWrapper />,
        errorElement: <ErrorChatbot />,
    },
    {
        path: '*',
        element: <>
            <Nav />
            <Home />
        </>,
        errorElement: <Error />,
    },
]);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <RouterProvider router={router} />
            </LocalizationProvider>
        </ThemeProvider>
    </React.StrictMode>
);
