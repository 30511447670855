import { useEffect, useState } from 'react';

export const Routes = {
};

export const getApi = () => getHost();

export const getHost = () => {
    // If a cookie named "api" is given, return that
    const api = document.cookie.split('; ').find(row => row.startsWith('api='));
    if (api) {
        return api.split('=')[1];
    }

    const host = window.location.host;

    if (host.startsWith('dev.taiger.test')) {
        return 'http://dev.taiger.test:48967';
    }
    if (host === 'taiger.tools') {
        return 'https://api.taiger.tools';
    }
    if (host === 'int.taiger.tools') {
        return 'https://api.int.taiger.tools';
    }
    if (host === 'staging.taiger.tools') {
        return 'https://api.staging.taiger.tools';
    }
    if (host === 'tbs.taiger.tools') {
        return 'https://tbs.taiger.tools';
    }

    return 'https://api.taiger.tools';
};

export const getCurrentPage = () => {
    const pathname = window.location.pathname;

    if (pathname.startsWith('/home') || pathname === '/') {
        return 'home';
    }

    if (pathname.startsWith('/config')) {
        return 'config';
    }

    return 'nothing';
};

export const isDev = () => getHost() === 'http://dev.taiger.test:48967';


export function CreateArrayFromData(parameter: string, data: any): any {
    /*
     * Php expects multiple parameters like xyz[0]=a&xyz[1]=b&xyz[2]=c
     * generate that from data
     */
    const array: any = {};
    for (let i = 0; i < data.length; i++) {
        array[parameter + '[' + i + ']'] = data[i];
    }
    return array;
}

export function GetWithHook(url: string, params: any): any {
    const [response, setResponse] = useState({});

    useEffect(() => {
        // Replace params in url
        for (const param in params) {
            // eslint-disable-next-line
            url = url.replace('{' + param + '}', params[param]);
        }

        // Fetch with cookie
        fetch(getApi() + url, {
            credentials: 'include',
        })
            .then(response => response.json())
            .then(data => {
                setResponse(data);
            });
    }, []);

    return response;
}

export function GetWithoutHook(url: string, params: any): any {
    // Replace params in url
    for (const param in params) {
        // eslint-disable-next-line no-param-reassign
        url = url.replace('{' + param + '}', params[param]);
    }

    fetch(getApi() + url, {
        credentials: 'include',
    })
        .then(response => response.json())
        .then(data => data);
}

export async function GetWithPromise(url: string, params: any): Promise<any> {
    // Replace params in url
    for (const param in params) {
        // eslint-disable-next-line no-param-reassign
        url = url.replace('{' + param + '}', params[param]);
    }

    return fetch(getApi() + url, {
        credentials: 'include',
    })
        .then(response => response.json())
        .then(data => data);
}

export function PostWithoutHook(url: string, params: any): any {
    // Replace params in url
    for (const param in params) {
        // eslint-disable-next-line
        url = url.replace('{' + param + '}', params[param]);
    }

    const formData = new FormData();
    for (const key in params) {
        formData.append(key, params[key]);
    }

    fetch(getApi() + url, {
        method: 'POST',
        body: formData,
        credentials: 'include',
    })
        .then(response => response.json())
        .then(data => data);
}

export function PostWithHook(url: string, params: any): any {
    const [response, setResponse] = useState({});

    useEffect(() => {
        // Replace params in url
        for (const param in params) {
            // eslint-disable-next-line
            url = url.replace('{' + param + '}', params[param]);
        }

        const formData = new FormData();
        for (const key in params) {
            formData.append(key, params[key]);
        }

        fetch(getApi() + url, {
            method: 'POST',
            body: formData,
            credentials: 'include',
        })
            .then(response => response.json())
            .then(data => {
                setResponse(data);
            });

    }, []);

    return response;
}

export function PostWithPromise(url: string, params: any, files?: any): Promise<any> {
    // Replace params in url
    for (const param in params) {
        // eslint-disable-next-line
        url = url.replace('{' + param + '}', params[param]);
    }

    const formData = new FormData();

    if (files) {
        for (const file of files) {
            formData.append('files[]', file);
        }
    }

    for (const key in params) {
        formData.append(key, params[key]);
    }

    return fetch(getApi() + url, {
        method: 'POST',
        body: formData,
        credentials: 'include',
    });
}

export function PostWithoutHookWithFile(url: string, params: any, file: any): any {
    // Replace params in url
    for (const param in params) {
        // eslint-disable-next-line
        url = url.replace('{' + param + '}', params[param]);
    }

    const formData = new FormData();
    formData.append('file', file);

    for (const key in params) {
        formData.append(key, params[key]);
    }

    fetch(getApi() + url, {
        method: 'POST',
        body: formData,
        credentials: 'include',
    })
        .then(response => response.json())
        .then(data => data);
}

export function PostWithoutHookWithMultipleFiles(url: string, params: any, files: any): any {
    // Replace params in url
    for (const param in params) {
        // eslint-disable-next-line
        url = url.replace('{' + param + '}', params[param]);
    }

    const formData = new FormData();
    for (const file of files) {
        formData.append('files[]', file);
    }

    for (const key in params) {
        formData.append(key, params[key]);
    }

    fetch(getApi() + url, {
        method: 'POST',
        body: formData,
        credentials: 'include',
    })
        .then(response => response.json())
        .then(data => data);
}

export function PostWithPromiseWithMultipleFiles(url: string, params: any, files: any): Promise<any> {
    // Replace params in url

    for (const param in params) {
        // eslint-disable-next-line
        url = url.replace('{' + param + '}', params[param]);
    }

    const formData = new FormData();
    for (const file of files) {
        formData.append('files[]', file);
    }

    for (const key in params) {
        formData.append(key, params[key]);
    }

    return fetch(getApi() + url, {
        method: 'POST',
        body: formData,
        credentials: 'include',
    });
}
