import { useParams } from 'react-router-dom';
import Chatbot from '../generals/Chatbot';

function ChatbotWrapper() {
    const { id } = useParams<{ id: string }>();

    return (
        <Chatbot
            chatbot={id!}
            isExternal={true}
        />
    );
}

export default ChatbotWrapper;
