import { Button, FormControl, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import Modal from '../generals/Modal';
import { Verify2EndpointPostPromise } from '../routes/Verify';

interface Props {
    open: boolean;
    close: () => void;
    translationsState: any;
    email: string;
}

function PINModal(props: Props) {
    const [pinState, setPinState] = useState('');

    const handlePIN = () => {
        const promise = Verify2EndpointPostPromise(props.email, '', pinState);

        promise.then((value: any) => {
            if (value.status === 200) {
                value.json().then((data: any) => {
                    if (data.data.verified === true) {
                        localStorage.setItem('hash', data.data.hash);
                        window.location.reload();
                        props.close();
                    } else {
                        setPinState('');
                    }
                });
            }
        });

    };

    return (
        <Modal
            open={props.open}
            close={props.close}
            sx={{
                width: 'auto'
            }}
        >
            <Typography variant="h4" component="div" gutterBottom>
                {props.translationsState.pin}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {props.translationsState.pinText}
            </Typography>
            <FormControl>
                <TextField
                    id="pinField"
                    name="pin"
                    value={pinState}
                    onChange={(ev) => setPinState(ev.target.value)}
                    onKeyUp={(ev) => {
                        if (ev.key === 'Enter') {
                            handlePIN();
                            ev.preventDefault();
                        }
                    }}
                    label={props.translationsState.pin}
                    variant="standard"
                    type="password"
                />
                <Button
                    variant="outlined"
                    sx={{
                        my: 1,
                        mx: 1.5,
                    }}
                    onClick={handlePIN}
                >
                    {props.translationsState.verify}
                </Button>
            </FormControl>
        </Modal>
    );
}

export default PINModal;

