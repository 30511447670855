import { Box, Modal as MuiModal } from '@mui/material';
import CloseButton from './CloseButton';
import { modalStyle } from './modalstyle';

interface Props {
    open: boolean;
    close: () => void;
    children: any;
    sx?: any;
}

function Modal(props: Props) {
    const modalStyleWithSx = props.sx
        ? { ...modalStyle, ...props.sx }
        : modalStyle;

    return (
        <div>
            <MuiModal
                open={props.open}
                onClose={props.close}
                aria-labelledby={Math.random().toString()}
                aria-describedby={Math.random().toString()}
            >
                <Box
                    sx={modalStyleWithSx}
                >
                    <CloseButton
                        close={props.close}
                    />
                    {props.children}
                </Box>
            </MuiModal>
        </div>
    );
}

export default Modal;

