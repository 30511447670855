import { Button, FormControl, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import Modal from '../generals/Modal';
import useTranslationState from '../generals/useTranslationState';
import CreateChatbotResponse from '../responses/CreateChatbot';
import { CreateChatbot2EndpointPostPromise } from '../routes/CreateChatbot';

interface Props {
    open: boolean;
    close: () => void;
}

function Create(props: Props) {
    const translationState = useTranslationState({
        create: '',
        title: '',
        description: '',
        instructions: '',
        giveYourChatbotAName: '',
    });

    const [titleState, setTitleState] = useState('');

    const createChatbot = () => {
        const promise = CreateChatbot2EndpointPostPromise(
            titleState,
            '',
            '',
        );

        promise.then((response) => {
            if (response.status === 200) {
                response.json().then((data: CreateChatbotResponse) => {
                    const id = data.data.id;

                    window.location.href = `/config/${id}`;
                    props.close();
                });
            }
        });
    };

    return (
        <Modal
            open={props.open}
            close={props.close}
        >
            <Typography
                variant="h5"
                sx={{
                    marginBottom: 4,
                }}
            >
                {translationState.giveYourChatbotAName}
            </Typography>
            <FormControl fullWidth>
                <TextField
                    label={translationState.title}
                    value={titleState}
                    onChange={(e) => setTitleState(e.target.value)}
                    sx={{
                        marginBottom: 4,
                    }}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                            createChatbot();
                        }
                    }}
                />
            </FormControl>
            <FormControl>
                <Button
                    variant="contained"
                    onClick={createChatbot}
                >
                    {translationState.create}
                </Button>
            </FormControl>
        </Modal>
    );
}

export default Create;
