import { Box, Container, Skeleton, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Conversation from './Conversation';
import Chatbot from './generals/Chatbot';
import useFrequentEndpoint from './generals/useFrequentEndpoint';
import useTranslationState from './generals/useTranslationState';
import { useBlack } from './hooks/useBlack';
import useIsMobile from './hooks/useIsMobile';
import ListConversationsResponse from './responses/ListConversations';
import { ListConversations2EndpointGetHook, ListConversations2EndpointGetPromise } from './routes/ListConversations';

interface ConversationItem {
    chatId: string;
    title: string;
}

function ConversationCenter() {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    useBlack();
    const translationState = useTranslationState({
        conversations: '',
        config: '',
        assistant: '',
        user: '',
        conversation: '',
    });

    const [listConversations, setListConversations] = useFrequentEndpoint<ListConversationsResponse, any>(ListConversations2EndpointGetHook(id!), ListConversations2EndpointGetPromise, 5, id!);

    const [conversation, setConversation] = useState<string | null>();

    useEffect(() => {
        if (listConversations.status === 200 && conversation === undefined && listConversations.data.conversations.length > 0) {
            setConversation(listConversations.data.conversations[0].chatId);
        }
    }, [listConversations, conversation]);

    return (
        <Container
            maxWidth="xl"
            sx={{
                paddingY: 5,
                minHeight: { xs: '130vh', md: 'calc(100vh - 133px)' },
            }}
        >
            {!isMobile &&
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mb: 2,
                    }}
                >
                    <ToggleButtonGroup
                        sx={{
                            backgroundColor: 'white',
                        }}
                    >
                        <ToggleButton
                            value="left"
                            selected={false}
                            onClick={() => {
                                navigate('/config/' + id, { replace: true });
                            }}
                        >
                            {translationState.config}
                        </ToggleButton>
                        <ToggleButton
                            value="right"
                            selected={true}
                            onClick={() => {
                                navigate('/conversations/' + id, { replace: true });
                            }}
                        >
                            {translationState.conversations}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            }
            <Box
                sx={{
                    display: 'flex',
                    height: 800,
                    backgroundColor: 'white',
                    borderRadius: 4,
                    p: 2,
                }}
            >
                <Box
                    sx={{
                        width: '33%',
                        height: '100%',
                        overflowY: 'scroll',
                        borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        {translationState.conversations}
                    </Typography>
                    {listConversations.status === 200 && listConversations.data.conversations.map((conversationItem: ConversationItem) => (
                        <Box
                            key={conversationItem.chatId}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: 2,
                                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                cursor: 'pointer',
                                backgroundColor: conversation === conversationItem.chatId
                                    ? 'rgba(0, 0, 0, 0.1)'
                                    : 'white',
                            }}
                            onClick={() => {
                                setConversation(conversationItem.chatId);
                            }}
                        >
                            {conversationItem.title}
                        </Box>
                    ))}
                    <Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '66%',
                        height: '100%',
                        backgroundColor: 'white',
                        position: 'relative',
                    }}
                >
                    {conversation === undefined &&
                        <Skeleton
                            variant="rectangular"
                            sx={{
                                height: '100%',
                            }}
                        />
                    }
                    {conversation !== undefined &&
                        <Conversation
                            chatId={conversation!}
                            translationState={translationState}
                        />
                    }
                </Box>
            </Box>
            <Chatbot
                chatbot={id!}
                isPreview={true}
            />
        </Container>
    );
}

export default ConversationCenter;
