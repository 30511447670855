import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function Verify2EndpointGetHook(
    param_email: string, 
    param_token: string, 
    param_pin: string, 
) {
    return GetWithHook(routes.Verify, {
        email: param_email, 
        token: param_token, 
        pin: param_pin, 
    });
}

export function Verify2EndpointGetPromise(
    param_email: string, 
    param_token: string, 
    param_pin: string, 
) {
    return GetWithPromise(routes.Verify, {
        email: param_email, 
        token: param_token, 
        pin: param_pin, 
    });
}

export function Verify2EndpointPostPromise(
    param_email: string, 
    param_token: string, 
    param_pin: string, 
) {
    return PostWithPromise(routes.VerifyPost, {
        email: param_email, 
        token: param_token, 
        pin: param_pin, 
    });
}

