import { Box, CircularProgress, Typography } from '@mui/material';

interface Props {
    index: number;
    chatbotTitle: string;
    primaryColor: string;
    secondaryColor: string;
}

function ThinkingMessage(props: Props) {
    return (
        <Box
            key={props.index}
            sx={{
                paddingX: 2,
                textAlign: 'left',
                alignSelf: 'flex-start',
                backgroundColor: props.primaryColor,
                color: props.secondaryColor,
                marginBottom: 2,
                borderRadius: '0 16px 16px 16px',
                maxWidth: '80%',
                marginX: 1,
                marginTop: 4,
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    right: 'auto',
                    left: 0,
                    marginTop: -2.5,
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: props.primaryColor,
                    }}
                >
                    {props.chatbotTitle}
                </Typography>
            </Box>
            <Typography
                variant="body1"
                sx={{
                    fontSize: 16,
                    color: 'white',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    paddingY: 2,
                    paddingX: 4,
                }}
            >
                <CircularProgress
                    size={40}
                    color="inherit"
                />
            </Typography>
        </Box>
    );
}

export default ThinkingMessage;
