import { Box, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';

interface Props {
    message: any;
    index: number;
    chatbotTitle: string;
    primaryColor: string;
    secondaryColor: string;
    reverse?: boolean;
}

function Message(props: Props) {
    const getAlignment = (isFlex: boolean) => {
        if (props.message.role === 'assistant') {
            return props.reverse
                ? (isFlex ? 'flex-end' : 'left')
                : (isFlex ? 'flex-start' : 'left');
        }

        return props.reverse
            ? (isFlex ? 'flex-start' : 'left')
            : (isFlex ? 'flex-end' : 'left');
    };

    return (
        <Box
            key={props.index}
            sx={{
                paddingX: 2,
                textAlign: getAlignment(false),
                alignSelf: getAlignment(true),
                backgroundColor: props.message.role === 'assistant' ? props.primaryColor : 'rgba(0, 0, 0, 0.1)',
                marginBottom: 2,
                borderRadius: props.message.role === 'assistant'
                    ? (props.reverse ? '16px 0 16px 16px' : '0 16px 16px 16px')
                    : (props.reverse ? '0 16px 16px 16px' : '16px 0 16px 16px'),
                maxWidth: '80%',
                marginX: 1,
                marginTop: 4,
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    right: (props.message.role === 'assistant'
                        ? (props.reverse ? 0 : 'auto')
                        : (props.reverse ? 'auto' : 0)),
                    left: (props.message.role === 'assistant'
                        ? (props.reverse ? 'auto' : 0)
                        : (props.reverse ? 0 : 'auto')),
                    marginTop: -2.5,
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: props.primaryColor,
                    }}
                >
                    {props.message.role === 'assistant' ? props.chatbotTitle : 'User'}
                </Typography>
            </Box>
            <Typography
                variant="body1"
                sx={{
                    fontSize: 16,
                    color: props.message.role === 'assistant' ? 'white' : 'black',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                }}
            >
                <ReactMarkdown>
                    {props.message.content}
                </ReactMarkdown>
            </Typography>
        </Box>
    );
}

export default Message;
