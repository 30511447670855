import React from 'react';
import './App.css';
import { Typography } from '@mui/material';
import Nav from './generals/nav';
import { useRouteError } from 'react-router-dom';

function Error() {
    const error = useRouteError() as Error;

    const message = error.message as string;

    return (
        <>
            <Nav />
            <div className="content">
                <Typography variant="h1" component="h1" gutterBottom>
                    The developers have been notified of this error and will fix it as soon as possible.
                </Typography>
                <Typography variant="h2" component="h2" gutterBottom>
                    Error: {message}
                </Typography>
            </div>
        </>
    );
}

export default Error;

